<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="align-center pr-2 pb-5  " style="width: 100%">
              <div class="d-inline-flex align-center justify-space-between max-width">
                <!-- Left-aligned elements -->
                <div class="d-flex align-center">
                  <v-switch
                    v-model="activeOnly"
                    data-cy="switch-active-only"
                    :label="$lang.labels.activeOnly"
                    class="pl-2 pt-1 pr-3"
                    dense
                    inset
                  ></v-switch>
                  <v-text-field
                    v-model="search"
                    class="custom-search-perm"
                    outlined
                    clearable
                    hide-details
                    dense
                    :label="$lang.labels.searchByName"
                    data-cy="search-by-name"
                  ></v-text-field>
                  <v-select
                    v-model="listenStatus"
                    :items="formattedListenStatuses"
                    outlined
                    dense
                    hide-details
                    clearable
                    :label="$lang.labels.listenStatus"
                    class="ml-2 custom-search-perm"
                    style="max-width: 200px"
                  ></v-select>
                  <v-select
                    v-model="listenType"
                    :items="formattedListenTypes"
                    outlined
                    dense
                    hide-details
                    clearable
                    :label="$lang.labels.listenType"
                    class="ml-2 custom-search-perm"
                    style="max-width: 220px"
                  ></v-select>
                </div>

                <!-- Right-aligned buttons -->
                <div class="d-flex align-center ml-auto">
                  <v-btn
                    text
                    light
                    class="ml-2"
                    color="primary"
                    @click="sendQuery()"
                  >
                    <v-icon dense small>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="roles.includes('TRIGGER_CREATOR') || roles.includes('SUPER_USER')"
                    outlined
                    color="accent"
                    class="color-accent-text mr-1"
                    :to="{ name: 'eventHandlerCreate', params: { lang: $lang.current_lang } }"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-file-plus
                    </v-icon>
                    {{ $lang.actions.create }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-inline-flex align-center pr-2  pl-2" style="width: 100%">
              <v-switch
                v-model="listenAllProcesses"
                :label="$lang.labels.showListenAllProcesses"
                class=" pr-3"
                dense
                inset
              >
              </v-switch>
              <v-autocomplete
                v-model="processId"
                outlined
                dense
                :items="processes"
                :loading="isLoadingProcesses"
                :search-input.sync="searchProcesses"
                hide-no-data
                clearable
                hide-details
                item-text="name"
                item-value="id"
                :label="$lang.labels.process"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                class="ml-2 custom-search-perm"
              ></v-autocomplete>
              <v-autocomplete
                v-model="listenProcessId"
                outlined
                dense
                :items="listenProcesses"
                :loading="isLoadingListenProcesses"
                :search-input.sync="searchListenProcesses"
                hide-no-data
                clearable
                hide-details
                item-text="name"
                item-value="id"
                :label="$lang.labels.listenProcess"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                class="ml-2 custom-search-perm"
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex justify-space-between align-center">
          <!-- Name on the left with a fixed or max width -->
          <div class="text-start name-container clickable-simple"> <!-- Adjust the margin-right here -->
            <router-link v-if="item.name.length < 86" class="text-decoration-none" :to="{ name: 'eventHandlerEdit', params: { id: item.id } }">
              <span class="font-weight-regular color-primary">{{ item.name }}</span>
            </router-link>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <router-link
                    class="text-decoration-none"
                    :to="{ name: 'eventHandlerEdit', params: { id: item.id } }"
                  >
                    <span class="font-weight-regular color-primary">{{ item.name.substring(0, 62) }}...</span>
                  </router-link>
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
          <!-- 3 dots menu on the right -->
          <div>
            <v-menu class="custom-edit-menu" offset-y nudge-left="75" transition="slide-y-transition"> <!-- Adjust nudge-left if necessary -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  variant="text"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <!-- Menu with actions -->
              <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid;">
                <v-list dense>
                  <!-- Deactivate/Activate button -->
                  <v-list-item
                    :disabled="item.isSystem"
                    link
                    class="text--secondary font-weight-light"
                    @click="$emit('changeStatus', {id: item.id, status: item.status})"
                  >
                    {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                  </v-list-item>

                  <!-- Copy Create button -->
                  <v-list-item
                    v-if="roles.includes('TRIGGER_CREATOR') || roles.includes('SUPER_USER')"
                    link
                    class="text--secondary font-weight-light"
                    @click="copyCreateResource(item, 'eventHandlerCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
                  >
                    {{ $lang.actions.copyCreate }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.listenStatus="{ item }">
        <div>{{ $lang.status[item.listenStatus] }}</div>
      </template>

      <template v-slot:item.listenType="{ item }">
        <div>{{ $lang.status[item.listenType] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.listenProcessId="{ item }">
        <div>{{ finishInit && item.listenProcessId ? formatProcess(item.listenProcessId) : $lang.status.ALL }}</div>
      </template>

      <template v-slot:item.comment="{ item }">
        <div style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis">{{ item.comment }}</div>
      </template>

      <template v-slot:item.basicAccessAuthentication="{ item }">
        <div>{{ item.basicAccessAuthentication ? $lang.status.YES : $lang.status.NO }}</div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getProcessByIdUsingGET as getProcess, getProcessesUsingGET as getProcesses } from '@/utils/api'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.listenType, value: 'listenType' },
        { text: this.$lang.labels.listenStatus, value: 'listenStatus' },
        { text: this.$lang.labels.listenProcess, value: 'listenProcessId', sortable: false },
        { text: this.$lang.labels.status, value: 'status', sortable: true }
        // { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      isLoadingProcesses: false,
      processId: '',
      processes: [],
      searchProcesses: '',
      listenAllProcesses: false,
      listenProcessId: '',
      listenStatus: '',
      listenType: '',
      isLoadingListenProcesses: false,
      listenProcesses: [],
      listenTypes: definitions.TriggerEventHandlerRequest.properties.listenType.enum,
      listenStatuses: definitions.TriggerEventHandlerRequest.properties.listenStatus.enum,
      searchListenProcesses: '',
      finishInit: false
    }
  },
  computed: {
    formattedListenStatuses() {
      return this.listenStatuses.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    formattedListenTypes() {
      return this.listenTypes.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.sendQuery()
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler: debounce( function (val) {
        if (!this.lock && val && val.length > 1) this.sendQuery()
        if (!this.lock && val === null || val === '') this.sendQuery()
      }, 500)
    },
    activeOnly: {
      handler () {
        if (!this.lock) this.sendQuery()
      }
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }
    },
    searchListenProcesses: {
      handler(val) {
        if (val && val.length > 1) this.searchListenProcessesFunction(val)
      }
    },
    processId: {
      handler() {
        if (!this.lock) this.sendQuery()
      }
    },
    listenAllProcesses: {
      handler() {
        if (!this.lock) this.sendQuery()
      }
    },
    listenProcessId: {
      handler() {
        if (!this.lock) this.sendQuery()
      }
    },
    listenStatus: {
      handler() {
        if (!this.lock) this.sendQuery()
      }
    },
    listenType: {
      handler() {
        if (!this.lock) this.sendQuery()
      }
    },
    items: {
      handler () {
        if (this.items && this.items.items && this.items.items.length > 0) {
          if (!this.lock) this.init()
        } else {
          this.finishInit = true
        }
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(5, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(5, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      if (this.$route.query && this.$route.query.processId) {
        this.processId = this.$route.query.processId
      }
      if (this.$route.query && this.$route.query.listenProcessId) {
        this.listenProcessId = this.$route.query.listenProcessId
      }
      if (this.$route.query && this.$route.query.search) {
        this.search = this.$route.query.search
      }
      if (this.$route.query.activeOnly) {
        this.activeOnly = this.$route.query.activeOnly === 'true'
      }
    } else if (localStorage.preFillData) {

      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.eventHandlerList) {
        this.search = preFill.eventHandlerList.search
        this.activeOnly = preFill.eventHandlerList.activeOnly
        this.processId = preFill.eventHandlerList.processId
        this.listenAllProcesses = preFill.eventHandlerList.listenAllProcesses
        this.listenProcessId = preFill.eventHandlerList.listenProcessId
        this.listenStatus = preFill.eventHandlerList.listenStatus
        this.listenType = preFill.eventHandlerList.listenType
        this.options = preFill.eventHandlerList.options
      }
    }
    if (this.processId) {
      this.loadPreFill()
    } else {
      setTimeout(() => {
        this.lock = false
        this.sendQuery()
      }, 100)
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    async init() {
      this.finishInit = false

      const listenProcessIds = this.items.items.map((item) => item.listenProcessId).filter((item) => item !== null && item !== undefined && item !== '')

      const fetchedProcesses = listenProcessIds.map(async (item) => {
        return this.fetchProcess(item)
      })

      await Promise.all(fetchedProcesses)
        .then((result) => {
          this.fetchedProcesses = result

          this.finishInit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatProcess(id) {
      const findById = this.fetchedProcesses && this.fetchedProcesses.length > 0 ? this.fetchedProcesses.find((process) => process.id === id) : null

      if (findById) return findById.name

      return ''
    },
    async fetchProcess(id) {
      return new Promise((resolve, reject) => {
        getProcess({ id })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    copyCreateResource,
    loadPreFill() {
      this.isLoadingProcesses = true
      if (this.processId) {
        getProcess({
          id: this.processId
        })
          .then((res) => {
            this.processId = ''
            this.processes = [res.data.data]
            this.isLoadingProcesses = false
            this.searchProcesses = res.data.data.name
            this.processId = res.data.data.id
            setTimeout(() => {
              this.lock = false
            }, 50)
          })
          .catch((err) => {
            this.isLoadingProcesses = false
            this.addSnackbar({
              message:  err,
              timeout: 5000,
              color: 'error'
            })            })
      }
      if (this.listenProcessId) {
        getProcess({
          id: this.listenProcessId
        })
          .then((res2) => {
            this.listenProcessId = ''
            this.listenProcesses = [res2.data.data]
            this.isLoadingListenProcesses = false
            this.searchListenProcesses = res2.data.data.name
            this.listenProcessId = res2.data.data.id
            setTimeout(() => {
              this.lock = false
            }, 50)
          })
          .catch((err) => {
            this.isLoadingListenProcesses = false
            this.addSnackbar({
              message:  err,
              timeout: 5000,
              color: 'error'
            })            })
      }
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    searchListenProcessesFunction(val = '') {
      this.isLoadingListenProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.listenProcesses = res.data.data.items
          this.isLoadingListenProcesses = false
        })
        .catch((err) => {
          this.isLoadingListenProcesses = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.eventHandlerList) preFill.eventHandlerList = {
          search: '',
          activeOnly: true,
          processId: '',
          listenAllProcesses: '',
          listenProcessId: '',
          listenStatus: '',
          listenType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.eventHandlerList.search = this.search
        preFill.eventHandlerList.activeOnly = this.activeOnly
        preFill.eventHandlerList.processId = this.processId
        preFill.eventHandlerList.listenAllProcesses = this.listenAllProcesses
        preFill.eventHandlerList.listenProcessId = this.listenProcessId
        preFill.eventHandlerList.listenStatus = this.listenStatus
        preFill.eventHandlerList.listenType = this.listenType
        preFill.eventHandlerList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    async sendQuery(resetPage = true) {
      if (this.lock) return
      const obj = { options: this.options, processId: this.processId, search: this.search, activeOnly: this.activeOnly, listenAllProcesses: this.listenAllProcesses, listenProcessId: this.listenProcessId, listenStatus: this.listenStatus, listenType: this.listenType }

      obj.resetPage = resetPage

      this.$emit('fetchEventHandlers', obj)
      this.savePreFill()
    }
  }
}
</script>

<style>
.v-btn:before {
  background-color: transparent;
}
</style>
